import React, { useEffect, useRef, useState } from "react";
import { baseURL, headers } from "../../../../../global/api";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import { setHeading } from "../../../accounts/account.actions";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Modal from "react-bootstrap/Modal";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { filter, isEmpty } from "lodash";
import moment from "moment";
import { deleteReceiving, deleteReceivingProduct, generatePurchasePDF, getPurchaseByDate, updateReceiving, updateReceivingProduct } from "./Receiving.action";
const ViewPurchaseWithDate = (props) => {

  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const handleClose = () => {
    setOpen(false);
  };
  const tableRef = useRef();
  function updateTableQuery () {
    tableRef.current.onQueryChange()
  };
  function uploadOrder() {
    props.getPurchaseByDate(
      {
        startDate: startDate,
        endDate: endDate
      },
    );

    setStartDate(moment(new Date()).format("YYYY-MM-DD"))
    setEndDate(moment(new Date()).format("YYYY-MM-DD"))
  }
  useEffect(() => {

    props.setHeading("View Purchase");
    // props.getArea();
  }, []);
  return (
    <>
      {props.isFetchingPayment ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
              <Row>
            <Col >
              <FormGroup>
                <Form.Label>
                  <b>Start Date :</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>
              </FormGroup>
            </Col>
            <Col >
              <FormGroup>
                <Form.Label>
                  <b>End Date :</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Form.Control>
              </FormGroup>
            </Col>

          </Row>
          <div className="sendDiv" style={{marginTop:10}}>
            {isEmpty(startDate)||isEmpty(endDate) ? (
                <Button disabled>Get Purchase</Button>
            ) : (
                <Button onClick={uploadOrder}>Get Purchase</Button>
               
            )}
          </div>
          <div className="main" hidden={props.tableShow == false ? true : false} >
          <MaterialTable
              title="Purchase Details"
              // tableRef={tableRef}
              columns={[
                // { title: 'Flavors Id', field: 'table_id', editable: 'never' },
                { title: "Purchase Date", field: "receivingDate" },
                {
                  title: "WareHouse Name",
                  field: "restaurantId.wareHouseName",
                  editable: "never",
                },
                { title: "Received By", field: "receivedBy" },
                { title: "Sub Total", field: "total" },
                { title: "Discount", field: "discount" },
                { title: "Freight", field: "freight" },
                { title: "Grand Total", field: "grandTotal" },
                {
                  title: "Purchase Description",
                  field: "recivingDescription",
                },
              ]}
              data={props.purchaseList}
              // data={(query) =>
              //   new Promise((resolve, reject) => {
              //     let url = `${baseURL}/receiving?`;
              //     url += "limit=" + query.pageSize;
              //     url += "&page=" + query.page;
              //     url += "&search=" + query.search;
              //     fetch(url, {
              //       method: "GET",
              //       headers: headers,
              //     })
              //       .then((response) => response.json())
              //       .then((result) => {
              //         resolve({
              //           data: result.data,
              //           page: result.page,
              //           totalCount: result.total_results,
              //         });
              //       });
              //   })
              // }
              detailPanel={(rowData) => {
                return (
                  <MaterialTable

                    //columns={state.columnsData}
                    columns={[
                      { title: "Product Name ", field: "ProductName" ,editable: "never",},
                      {
                        title: "Available Quantity",
                        field: "availableProductQuantity",
                        editable: "never",
                      },
                      {
                        title: "Add Quantity",
                        field: "requiredProductQuantity",
                      },
                      {
                        title: "Price",
                        field: "TotalProductPrice",
                        editable: "never",
                      },
                    ]}
                    data={rowData.receivingData}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: false,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            {
                              props.updateReceivingProduct(newData, oldData,rowData,updateTableQuery)
                            }
                          }, 600);
                        }),
                     onRowDelete: (oldData) =>
                       new Promise((resolve) => {
                         setTimeout(() => {
                           resolve();
                           props.deleteReceivingProduct(oldData,rowData,updateTableQuery);
                         }, 600);
                       }),
                   }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                toolbar: false,
                exportButton: false,
                sorting: true,
                paging: true,
                pageSize: 50, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              actions={[
                {
                  icon: () => <PictureAsPdfIcon />,
                  tooltip: "Generate PDF",
                  onClick: (event, rowData) => {
                    props.generatePurchasePDF(rowData._id);
                  },
                },
              ]}
              // onRowClick={(event, rowData, togglePanel) => togglePanel()}
              editable={{
                 onRowUpdate: (newData, oldData) =>
                   new Promise((resolve) => {
                     setTimeout(() => {
                       resolve();
                       {
                         props.updateReceiving(newData, oldData,updateTableQuery)
                       }
                     }, 600);
                   }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteReceiving(oldData._id,updateTableQuery);
                    }, 600);
                  }),
              }}
            />
          </div>
          <Modal
            show={open}
            onHide={handleClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ marginLeft: "15%" }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Return Product
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {props.isFetchingGenerateIssuancePDF ? (
                <div className="loader-div">
                  <Button variant="info" disabled>
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="info"
                    />
                    Loading...
                  </Button>
                </div>
              ) : (
                <MaterialTable
                  title="Product Detail"
                  columns={[
                    // {
                    //   title: "Code",
                    //   field: "product.code",
                    // },
                    {
                      title: "Product Name",
                      field: "productLabel",
                    },
                    {
                      title: "Quantity",
                      field: "quantity",
                    },
                  ]}
                  data={
                    !isEmpty(props.generateIssuancePDFList) &&
                    props.generateIssuancePDFList[0].returnProducts
                  }
                  options={{
                    actionsColumnIndex: -1,
                    toolbar: false,
                    exportButton: true,
                    sorting: true,
                    // filtering:true,
                    paging: true,
                    maxBodyHeight: "600px",
                    pageSize: 200, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                />
              )}
            </Modal.Body>
            {/* <Modal.Footer>
              <Button onClick={handleClose}>Close</Button>
            </Modal.Footer> */}
          </Modal>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data)),
    deleteReceiving: (tableId,updateTableQuery) => dispatch(deleteReceiving(tableId,updateTableQuery)),
    updateReceiving: (newData, oldData,updateTableQuery) => dispatch(updateReceiving(newData, oldData,updateTableQuery)),
    generatePurchasePDF: (_id) => dispatch(generatePurchasePDF(_id)),
    getPurchaseByDate:(data)=>dispatch(getPurchaseByDate(data)),
    updateReceivingProduct: (newData, oldData,rowData,updateTableQuery) => dispatch(updateReceivingProduct(newData, oldData,rowData,updateTableQuery)),
    deleteReceivingProduct: (data,rowData,updateTableQuery) => dispatch(deleteReceivingProduct(data,rowData,updateTableQuery)),
  
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingPurchase:
  state.RecivingReducer.isFetchingPurchase,
  purchaseList:
  state.RecivingReducer.purchaseList,
  tableShow:
  state.RecivingReducer.tableShow,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPurchaseWithDate);
