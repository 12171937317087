import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
} from "react-bootstrap";
import jsPDF from "jspdf";
import logo from "../../../../Images/logo.png";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../global/api";
import { UpdateUserRes, deleteUserRes, getUserRes } from "./user.actions";
import { isEmpty } from "lodash";
import { setHeading } from "../../accounts/account.actions";
import { getVendorData } from "../AddDetails/Vendor/Vendor.action";
const ViewUser = (props) => {
  let rollData = [
    {
      roll_id: 1,
      roll_name: "manager",
    },
    {
      roll_id: 2,
      roll_name: "Staff",
    },
    {
      roll_id: 3,
      roll_name: "Admin",
    },
  ];
  let roleArry = ["booker", "manager", "staff", "admin"];

  const rollList =
    !isEmpty(rollData) &&
    rollData.map((x) => {
      let data = { value: x.roll_id, label: x.roll_name };
      return data;
    });
  const getVendorList =
    !isEmpty(props.vendorDataList) &&
    props.vendorDataList.map((x) => {
      let data = { value: x._id, label: x.vendorName };
      return data;
    });
  useEffect(() => {
    props.setHeading("User List")
    props.getVendorData();
  }, []);
  return (
    <>
      {props.isFetchingUser || props.isFetchingVendor ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>

          <div className="main">
            <MaterialTable
              style={{ zIndex: 0 }}
              title="User"
              columns={[
                { title: "Name", field: "firstName" },
                // { title: "Role Name", field: "role" ,editable:'never'},
                {
                  title: "Choose a Role",
                  field: "role",
                  editComponent: ({ value, onChange }) => (
                    <select onChange={(e) => onChange(e.target.value)} style={{ padding: '10px' }}>
                      <option selected value={value}>
                        {value}
                      </option>
                      {roleArry.map(

                        (item) =>

                          item !== value && (
                            console.log(item, value, "tttttttt"),
                            <option key={item} value={item}>
                              {item}
                            </option>
                          )
                      )}
                    </select>
                  )
                },
                { title: "User Name", field: "userName" },
                { title: "Passward", field: "hash" },
                {
                  title: 'Vendor Name', field: 'vendorDetails.vendorName',
                  editComponent: ({ value, onChange }) => (
                    <select onChange={(e) => onChange(e.target.value)} style={{ padding: '10px' }}>
                      <option selected value={value}>
                        {value}
                      </option>
                      {getVendorList.map(

                        (item) =>

                          item.label !== value && (
                            console.log(item, value, "tttttttt22222"),
                            <option key={item.value} value={item.label}>
                              {item.label}
                            </option>
                          )
                      )}
                    </select>
                  )
                }
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/users/?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        // data:rollData,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.UpdateUser(newData, oldData, getVendorList);
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteUser(oldData._id);
                      console.log(oldData, "old");
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUserRes()),
    deleteUser: (id) => dispatch(deleteUserRes(id)),
    UpdateUser: (newData, oldData, getVendorList) => dispatch(UpdateUserRes(newData, oldData, getVendorList)),
    setHeading: (data) => dispatch(setHeading(data)),
    getVendorData: () => dispatch(getVendorData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);
