const initialState = {
  orderBookingList: [],
  isAddingOrderBooking: false,
  orderBookingDataList: [],

  isFetchingOrderBookingData: false,
  generateOrderPDFList: [],
  isFetchingGenerateOrderPDF: false,
  isFetchingOrderDetailById:false,
  orderDetailById:[],
  filterBooking:[],
  isFetchingOrderFilter:false,
  tableShow:false
};

const OrderBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_ORDER":
      return { ...state, isAddingOrderBooking: true };
    case "SUCCESS_ADD_ORDER":
      return {
        ...state,
        orderBookingList: action.payload,
        isAddingOrderBooking: false,
      };
    case "ERROR_ADD_ORDER":
      return { ...state, isAddingOrderBooking: false };
    case "REQUEST_GET_ORDER":
      return { ...state, isFetchingOrderBookingData: true };
    case "SUCCESS_GET_ORDER":
      return {
        ...state,
        orderBookingDataList: action.payload.data,
        isFetchingOrderBookingData: false,
      };
    case "ERROR_GET_ORDER":
      return { ...state, isFetchingOrderBookingData: false };

    case "REQUEST_GENERATE_ORDER_PDF":
      return { ...state, isFetchingGenerateOrderPDF: true };
    case "SUCCESS_GENERATE_ORDER_PDF":
      return {
        ...state,
        generateOrderPDFList: action.payload,
        isFetchingGenerateOrderPDF: false,
      };
      case "REQUEST_GET_ORDER_BY_ID":
        return { ...state, isFetchingOrderDetailById: true };
      case "SUCCESS_GET_ORDER_BY_ID":
        return {
          ...state,
          orderDetailById: action.payload.data,
          isFetchingOrderDetailById: false,
        };
      case "ERROR_GET_ORDER_BY_ID":
        return { ...state, isFetchingOrderDetailById: false };

        case "REQUEST_FILTER_ORDER":
          return { ...state, isFetchingOrderFilter: true,tableShow:false };
        case "SUCCESS_FILTER_ORDER":
          return {
            ...state,
            filterBooking: action.payload.data,
            isFetchingOrderFilter: false,
            tableShow:true
          };
        case "ERROR_FILTER_ORDER":
          return { ...state, isFetchingOrderFilter: false,tableShow:false };
    default:
      return state;
  }
};
export default OrderBookingReducer;
