import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import { addVendor } from "./Vendor.action";
import { setHeading } from "../../../accounts/account.actions";


const AddVendor = (props) => {
  const [vendorName, setVendorName] = useState("");
  const [vendorCnic, setVendorCnic] = useState();
  const [vendorPhone, setVendorPhone] = useState(0);
  const [vendorAddress, setVendorAddress] = useState("");
  // const [warehouseKey, setWarehouseKey] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  }

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  }

  function uploadVendor() {
    props.addVendor({
      vendorName: vendorName,
      vendorPhone: vendorPhone,
      vendorAddress: vendorAddress,
      vendorCnic: vendorCnic,
    }, handleVisible, handleDangerVisible);
  }
  useEffect(() => {
    props.setHeading("Add Vendor");
   // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isAddingVendor ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
          <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Vendor Name *</b></Form.Label>
                      <Form.Control type="text"  placeholder="Vendor Name...." onChange={(e) =>
                        setVendorName(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Vendor Phone </b></Form.Label>
                      <Form.Control type="text" placeholder="Vendor Phone...." value={vendorPhone} onChange={(e) => {
                        !isNaN(e.target.value) && setVendorPhone(e.target.value)
                      }
                      } />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Vendor CNIC </b></Form.Label>
                      <Form.Control type="Number"  placeholder="Vendor CNIC ...." onChange={(e) => {
                     setVendorCnic(e.target.value)
                      }
                      } />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Vendor Address </b></Form.Label>
                      <Form.Control type="text"  value={vendorAddress} placeholder="Vendor Address...." onChange={(e) =>
                        setVendorAddress(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {isEmpty(vendorName)  ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "#1976d2", border: "1px solid #1976d2" }}>Save</Button> : <Button onClick={uploadVendor}>Save</Button>}
                </div>

                
              </Form>

            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVendor: (data, handleVisible, handleDangerVisible) => dispatch(addVendor(data, handleVisible, handleDangerVisible)),
    setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  vendorList: state.VendorReducer.vendorList,
  isAddingVendor:state.VendorReducer.isAddingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
