import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, toLower } from "lodash";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
//import logo from "../../../../../Images/logo.png";
import logo from "../../../../Images/logo.png";
import {
  addUserRes,
  deleteUserRes,
  getUserRes,
  UpdateUserRes,
} from "./user.actions";
import { getRestaurantData } from "../AddDetails/AddResturant/Resturant.action";
import { setHeading } from "../../accounts/account.actions";
import { getVendorData } from "../AddDetails/Vendor/Vendor.action";
const AddUser = (props) => {
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [passwordUser, setPasswordUser] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [businessName, setBusinessName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roleName, setRoleName] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantname, setRestaurantName] = useState("");
  // const [lastName, setLastName]=useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [submit, setSubmit] = useState(false);
  function uploadCustomer() {
    props.addUser(
      {
        firstName: customerName,
        lastName: lastName,
        userName: userName,
        password: passwordUser,
        role: toLower(roleName),
        vendorId: vendorId,
        // businessName:businessName,
        warehouse: restaurantId,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  let rollData = [
    {
      roll_id: 1,
      roll_name: "Manager",
    },
    // {
    //   roll_id: 2,
    //   roll_name: "Staff",
    // },
    {
      roll_id: 3,
      roll_name: "Admin",
    },
    { roll_id: 5, roll_name: "Supervisor" },

    { roll_id: 6, roll_name: "SalesMen" },
    { roll_id: 7, roll_name: "Booker" },
    { roll_id: 8, roll_name: "BookerWholeSale" },
  ];
  const handleChangeRoll = (selectedOption) => {
    console.log(selectedOption, "sop");
    // setEventShiftId(selectedOption.value);
    setRoleName(selectedOption.label);
  };
  const rollList =
    !isEmpty(rollData) &&
    rollData.map((x) => {
      let data = { value: x.roll_id, label: x.roll_name };
      return data;
    });
  const handleChangeRestaurant = (selectedOption) => {
    console.log(selectedOption, "sop");
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
  };
  const getRestaurantList =
    !isEmpty(props.restaurantDataList) &&
    props.restaurantDataList.map((x) => {
      let data = { value: x._id, label: x.wareHouseName };
      return data;
    });
  const clearStates = () => {
    setLastName("");
    setPasswordUser("");
    setUserName("");
    setCustomerName("");
    setVendorId("");
    setVendorName("");
    //setCustomerPhone("");
    setSubmit(false);
  };
  const getVendorList =
    !isEmpty(props.vendorDataList) &&
    props.vendorDataList.map((x) => {
      let data = { value: x._id, label: x.vendorName };
      return data;
    });
  const handleChangeVendor = (selectedOption) => {
    console.log(selectedOption, "sop");
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
  };

  const CustomerData = isEmpty(props.userList) ? [] : props.userList.reverse();
  useEffect(() => {
    props.setHeading("Add User");
    props.getVendorData();
    props.getRestaurantData();
  }, []);
  return (
    <>
      {props.isFetchingRestaurant || props.isFetchingVendor ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col >
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Warehouse *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.restaurantDataList)}
                    placeholder="Select Warehouse..."
                    onChange={handleChangeRestaurant}
                    options={getRestaurantList}
                  />
                </Form.Group>
              </Col>
              <Col >
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Select Vendor *</b>
                  </Form.Label>
                  <Select
                    value={{ label: vendorName }}
                    isDisabled={
                      isEmpty(props.vendorDataList) || restaurantId == ""
                    }
                    placeholder="Select Vendor..."
                    onChange={handleChangeVendor}
                    options={getVendorList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter First Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name...."
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Last Name :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name...."
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter User Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="User Name...."
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              {showPassword == false ?
                <Col lg="6" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Enter User Password *</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        // type={showPassword==true?"text":"password"}
                        type="password"
                        minLength={8}
                        // maxLength={13}
                        placeholder="Enter User Password...."
                        value={passwordUser}
                        onChange={(e) => setPasswordUser(e.target.value)}
                      />
                      {/* <VisibilityIcon/> */}
                      <div className="search-icon" style={{ marginLeft: '-30px', zIndex: 5, marginTop: 5, cursor: 'pointer' }}>
                        <VisibilityIcon onClick={() => { setShowPassword(true) }} />
                      </div>
                    </InputGroup>
                  </Form.Group>
                </Col> :
                <Col lg="6" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Enter User Password *</b>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        // type={showPassword==true?"text":"password"}
                        type="text"
                        minLength={8}
                        // maxLength={13}
                        placeholder="Enter User Password...."
                        value={passwordUser}
                        onChange={(e) => setPasswordUser(e.target.value)}
                      />
                      {/* <VisibilityIcon/> */}
                      <div className="search-icon" style={{ marginLeft: '-30px', zIndex: 5, marginTop: 5, cursor: 'pointer' }}>
                        <VisibilityOffIcon onClick={() => { setShowPassword(false) }} />
                      </div>
                    </InputGroup>
                  </Form.Group>
                </Col>}
            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Role *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(rollData)}
                    placeholder="Select Role..."
                    onChange={handleChangeRoll}
                    options={rollList}
                  />
                </Form.Group>
              </Col>
              {/* <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Enter Business Name:</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Business Name...."
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </Form.Group>
            </Col> */}
            </Row>

            <div className="sendDiv">
              <Button
                disabled={
                  isEmpty(customerName) ||
                  isEmpty(passwordUser) ||
                  isEmpty(userName) ||
                  isEmpty(roleName) ||
                  isEmpty(restaurantId)||vendorId==""
                }
                onClick={() => {
                  uploadCustomer();
                  // setSubmit(true);
                }}
              >
                Save
              </Button>
            </div>
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data, handleVisible, handleDangerVisible) =>
      dispatch(addUserRes(data, handleVisible, handleDangerVisible)),
    getRestaurantData: () => dispatch(getRestaurantData()),
    setHeading: (data) => dispatch(setHeading(data)),
    getVendorData: () => dispatch(getVendorData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  addUser: state.UserReducer.addUser,
  isAddingUser: state.UserReducer.isAddingUser,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
