import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "../add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter, sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import useScanDetection from "use-scan-detection";

import { getProductTypeProduct } from "../Recipe/Recipe.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { addReceiving } from "./Receiving.action";
import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";
import { getProductData } from "../AddProduct/Product.action";
import { getVendorData } from "../Vendor/Vendor.action";
import { setHeading } from "../../../accounts/account.actions";

const AddReceving = (props) => {
  const [inputField1, setInputField1] = useState([
    {
      productId: 0,
      ProductName: "",
      availableProductQuantity: 0,
      requiredProductQuantity: 0,
      productPrice: 0,
      marketPrice: 0,
      NoOfCarton: 0,
      TotalProductPrice: 0,

      // uom: "",
      // uomName: "",
      // uomPrice:0,
      // quantity: 0,
      // price: 0,
    },
  ]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [wareHouseId, setwareHouseId] = useState("");
  const [wareHouselabel, setwareHouseLabel] = useState("");
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [requistionLabel, setRequistionLabel] = useState();
  const [department, setdepartment] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState("");
  const [requistionType, setRequistionType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantname, setRestaurantName] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [receivingTypeId, setReceivingTypeId] = useState(1);
  const [receivingTypeName, setReceivingTypeName] = useState("");
  const [totalBill, setTotalBill] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [Freight, setFreight] = useState(0);
  const [payableBill, setPayableBill] = useState(0);
  const [dangerVisibleAlertProduct, setDangerVisibleAlertProduct] =
    useState(false);
  const [barcode, setBarocode] = useState("");
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadPurchase() {
    props.addReceiving(
      {
        receivingDate: date,
        // requsitionType: "abc",//requistion,
        // department: "abc",//department,
        //issuedBy:"abc", //issuedBy,
        receivedBy: receivedBy,
        recivingDescription: requistionType,
        receivingData: inputField1,
        restaurantId: restaurantId,
        vendorId: vendorId,
        total: totalBill,
        discount: discount,
        freight: Freight,
        grandTotal: payableBill
        // barcodeId:barcode,
      },
      handleVisible,
      handleDangerVisible
    );
    setRequistionType("");
    setReceivedBy("");
    setIssuedBy("");
    setRequistionLabel("");
    setdepartment("");
    setRequistion("-1");
    setTotalBill(0)
    setPayableBill(0)
    setInputField1([
      {
        productId: 0,
        ProductName: "",
        availableProductQuantity: 0,
        requiredProductQuantity: 0,
        productPrice: 0,
        NoOfCarton: 0,
        TotalProductPrice: 0,
        // uom: "",
        // uomName: "",
        // uomPrice:0,
        // quantity: 0,
        marketPrice: 0,
      },
    ]);
  }

  const handleAddInput = () => {
    setInputField1([
      ...inputField1,
      {
        productId: 0,
        ProductName: "",
        availableProductQuantity: 0,
        requiredProductQuantity: 0,
        productPrice: 0,
        marketPrice: 0,
        NoOfCarton: 0,
        TotalProductPrice: 0,
        // uom: "",
        // uomName: "",
        // uomPrice:0,
        // quantity: 0,
        // price: 0,
      },
    ]);
  };
  //remove for add fuctio
  const handleRemoveInput = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    setInputField1(list);
  };
  const handleChange1 = (e, index, type, item) => {
    console.log(e, index, type, item, "checkBar");
    //  console.log(itemId,"lllll")
    //  let z = find(props.allInventory, (x) => x._id == e.value);
    let z = find(filterdProductList, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField1];
    console.log(z, "lllllll");
    if (type == "master") list[index]["productId"] = e.value;
    if (type == "master") list[index]["ProductName"] = e.label;
    if (type == "master") list[index]["piecePerBox"] = e.piecePerBox;
    console.log(e.label1, e.labelPrice, "hhhhh");
    if (type == "master")
      list[index]["availableProductQuantity"] = e.productQuantityCount;
    if (type == "master") list[index]["productPrice"] = e.productPrice;
    if (type == "master") setMaximumQuantity(Number(z.productBalance));
    if (type == "intquantity")
      list[index]["requiredProductQuantity"] =
        e.target.value < 0 ? alert("cannot added less than 0") : Number(e.target.value);
    if (type == "intquantity") list[index]["TotalProductPrice"] = Number(item.requiredProductQuantity) * Number(item.productPrice)
    if (type == "intCarton") {
      list[index]["NoOfCarton"] = Number(e.target.value);
      list[index]["requiredProductQuantity"] = Number(e.target.value) * Number(item.piecePerBox)
      console.log(Number(list[index]["requiredProductQuantity"]) * Number(item.productPrice), "iiiiiiiiiiiiiii")
      list[index]["TotalProductPrice"] = Number(item.requiredProductQuantity) * Number(item.productPrice)
    }
    if (type == "intPrice") list[index]["marketPrice"] = e.target.value;
    setInputField1(list);
    setTotalBill(sumBy(list, "TotalProductPrice"))
    setPayableBill(sumBy(list, "TotalProductPrice"))
  };

  const handleChangeRestaurant = (selectedOption) => {
    console.log(selectedOption, "sop");
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
  };
  const getRestaurantList =
    !isEmpty(props.restaurantDataList) &&
    props.restaurantDataList.map((x) => {
      let data = { value: x._id, label: x.wareHouseName };
      return data;
    });
  // vendorDataList
  const handleChangeVendor = (selectedOption) => {
    console.log(selectedOption, "sop");
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
  };
  const getVendorList =
    !isEmpty(props.vendorDataList) &&
    props.vendorDataList.map((x) => {
      let data = { value: x._id, label: x.vendorName };
      return data;
    });

  const handleChangeData = (selectedOption) => {
    console.log(selectedOption, "sop");
    setReceivingTypeId(selectedOption.value);
    setReceivingTypeName(selectedOption.label);
  };
  let data = [{ value: 0, label: 'Carton' },
  { value: 1, label: 'Piece' }
  ]
  const getDataList =
    data.map((x) => {
      let data = { value: x.value, label: x.label };
      return data;
    });

  const filterdProductList =
    !isEmpty(props.productDataList) &&
    props.productDataList.filter((e) => e.vendorId == vendorId);

  const getMList =
    !isEmpty(filterdProductList) &&
    filterdProductList.map((x) => {
      let data = {
        value: x._id,
        label: `${x.code}-${x.name}`,
        productQuantityCount: x.productBalance,
        productPrice: x.purchasePrice,
        piecePerBox: x.piecePerBox,

      };
      return data;
    });
  const handleDangerVisibleProduct = () => {
    setDangerVisibleAlertProduct(true);
    setTimeout(() => {
      setDangerVisibleAlertProduct(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "No Product Found",
      icon: "error",
      button: "Ok",
    });
  };
  // const barcod = (id) => {
  //   console.log(id, props.hybridAndCompleteData, "testtttttttttttttt");
  //   let i = find(props.hybridAndCompleteData, { barcodeId: id });
  //   console.log(i, "tttttttttttttttt");
  //   let b;
  //   isEmpty(i)
  //     ? handleDangerVisibleProduct()
  //     : (b = find(getMList, { value: i._id }));
  //   handleChange1(b, inputField1.length - 1, "master");
  // };
  // useScanDetection({
  //   onComplete: barcod,
  //   //  minLength: 13 // EAN13
  // });
  useEffect(() => {
    props.setHeading("Add Purchase");
    props.getProduct();
    props.getRestaurantData();
    props.getVendorData();
  }, []);
  return (
    <>
      {props.isFetchingProduct || props.isAddingReceiving ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingReceiving ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Warehouse *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.restaurantDataList)}
                    placeholder="Select Warehouse..."
                    onChange={handleChangeRestaurant}
                    options={getRestaurantList}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Vendor *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.vendorDataList) || restaurantId == ""}
                    placeholder="Select Vendor..."
                    onChange={handleChangeVendor}
                    options={getVendorList}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {/* <Col xl="6" lg="6" md="6" sm="6">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Bar Code Scaner</b></Form.Label>
                                    <Form.Control type="number" placeholder="Bar Code number...." value={barcode} disabled onBlur={(e) => setBarocode(e.target.value)} />
                                </Form.Group>
                            </Col> */}
              <Col xl="3" lg="3" sm="4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date:</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date...."
                    name="Date"
                    value={date}
                    onChange={(e) =>
                      setDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Form.Group>
              </Col>
              <Col xl="3" lg="3" md="3" sm="4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Received By *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Received By...."
                    name="receivedBy"
                    value={receivedBy}
                    onChange={(e) => setReceivedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="3" md="3" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Receiving Type *</b>
                  </Form.Label>
                  <Select
                    // isDisabled={isEmpty(props.vendorDataList)}
                    placeholder="Select Receiving Type..."
                    onChange={handleChangeData}
                    options={getDataList}
                  />
                </Form.Group>
              </Col>
              <Col xl="3" lg="3" md="3" sm="4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description...."
                    name="requistionType"
                    value={requistionType}
                    onChange={(e) => setRequistionType(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {inputField1.map((item, i) => {
              console.log(item, "check");
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="3" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>

                        <Select
                          style={{ zIndex: 1 }}
                          value={{
                            label: item.ProductName,
                          }}
                          placeholder="Select Items..."
                          name="master"
                          onChange={(e) => handleChange1(e, i, "master")}
                          isDisabled={isEmpty(vendorId)}
                          options={getMList}
                          isOptionDisabled={(option) => (
                            console.log(
                              option,
                              inputField1,
                              "kkkkkkkkkkkkkkkkkkkk"
                            ),
                            !isEmpty(
                              find(
                                inputField1,
                                (x) => x.productId == option.value
                              )
                            )
                          )}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Available Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Available Quantity...."
                          name="productQuantityCount"
                          disabled
                          value={item.availableProductQuantity}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Purchase Price *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Purchase Price...."
                          name="productQuantityCount"
                          disabled
                          value={item.productPrice}
                        />
                      </Form.Group>
                    </Col>
                    {receivingTypeId == 0 ? <>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Piece Per Carton *</b>
                          </Form.Label>
                          <Form.Control
                            placeholder="Piece Per Carton...."
                            name="piecePerBox"
                            disabled
                            value={item.piecePerBox}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>No # Carton *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Add Quantity...."
                            name="noOfCarton"
                            value={item.NoOfCarton}
                            onChange={(e) =>
                              handleChange1(e, i, "intCarton", item)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Add Quantity *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Add Quantity...."
                            // disabled={receivingTypeId==1}
                            name="productQuantity"
                            value={item.requiredProductQuantity}
                            onChange={(e) =>
                              handleChange1(e, i, "intquantity", item)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </> :
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Add Quantity *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Add Quantity...."
                            // disabled={receivingTypeId==1}
                            name="productQuantity"
                            value={item.requiredProductQuantity}
                            onChange={(e) =>
                              handleChange1(e, i, "intquantity", item)
                            }
                          />
                        </Form.Group>
                      </Col>}
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField1.length - 1 == i && (
                          <Button
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "white",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField1.length !== 1 && (
                          <Button
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              float: "right",
                              marginRight: "1%",
                              color: "white",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <Row>
              {receivingTypeId == 0 && <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Cartons</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Total Cartons...."
                    disabled
                    name="Total Cartons"
                    // value={sumBy(inputField1,"TotalProductPrice")}
                    value={sumBy(inputField1, 'NoOfCarton')}
                  />
                </Form.Group>
              </Col>}
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Quantity</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Total Quantity...."
                    disabled
                    name="Total Quantity"
                    // value={sumBy(inputField1,"TotalProductPrice")}
                    value={sumBy(inputField1, 'requiredProductQuantity')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {console.log(inputField1, "ttttttttttttttttttttt")}
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Bill</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Total Bill...."
                    disabled
                    name="Total Bill"
                    // value={sumBy(inputField1,"TotalProductPrice")}
                    value={totalBill}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Discount</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Discount...."
                    name="Discount"
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value)
                      setPayableBill(totalBill - Freight - e.target.value)
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Freight</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Add Freight...."
                    name="Add Freight"
                    value={Freight}
                    onChange={(e) => {
                      setFreight(e.target.value)
                      setPayableBill(totalBill - discount - e.target.value)
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Net Payable Bill</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Net Payable Bill...."
                    disabled
                    name="Net Payable Bill"
                    // value="1000"
                    value={payableBill}
                    onChange={() => { }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {isEmpty(restaurantId) ||
                isEmpty(vendorId) ||
                isEmpty(receivedBy) ||
                inputField1[0].productId == "" ||
                inputField1[0].requiredProductQuantity == 0 ? (
                <Button
                  disabled
                // style={{ color: "black" }}
                >
                  Save
                </Button>
              ) : (
                <Button onClick={uploadPurchase}>Save</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data)),
    addReceiving: (data, handleVisible, handleDangerVisible) =>
      dispatch(addReceiving(data, handleVisible, handleDangerVisible)),
    getMatrial: () => dispatch(getHybridAndCompleteData()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    getProduct: () => dispatch(getProductData()),
    getVendorData: () => dispatch(getVendorData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  isFetchingHybridAndCompleteData:
    state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isAddingReceiving: state.RecivingReducer.isAddingReceiving,
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReceving);
