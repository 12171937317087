import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { baseURL, headers } from "../../../../../global/api";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";

import { setHeading } from "../../../accounts/account.actions";
import { deleteOrder, deleteOrderProduct, generateOrderPDF, getOrder, getOrderById, updateOrder } from "./OrderBooking.action";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert";
const ViewOrderBooking = (props) => {
  const navigate = useNavigate();
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  const tableRef = useRef();
  function updateTableQuery () {
    tableRef.current.onQueryChange()
  };
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  useEffect(() => {
    props.setHeading("Order Booking List");
    // props.getArea();
  }, []);
  return (
    <>
      {props.isFetchingAreaData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
            tableRef={tableRef}
              title="Order Booking Details"
              columns={[
                {
                  title: "Booking ID",
                  field: "number",
                  editable: "never",
                },
                {
                  title: "Area Name",
                  field: "areaId.areaName",
                  editable: "never",
                },
                {
                  title: "Shop Name",
                  field: "shopId.shopName",
                  editable: "never",
                },
                { title: "Sub Total", field: "subTotal" },
                { title: "Discount", field: "discount" },
                // { title: "Tax", field: "tax" },
                { title: "Grand Total", field: "grandTotal" },
                { title: "Status", field: "status" },
                { title: "Date", field: "dateShow" },
                { title: "Booker", field: "bookerId.userName" },
                { title: "Salesman", field: "salesMenId.name" },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/booking?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              editable={{
                isDeletable:(rowData)=>rowData.status=='Booking Created'?true:false,
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                        props.deleteOrder(oldData._id,updateTableQuery);
                    }, 600);
                  }),
              }}
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    columns={[
                      {
                        title: "Code",
                        field: "code" ,
                        editable:'never'
                      },
                      {
                        title: "Product Name",
                        field: "productLabel",
                        editable:'never'
                      },
                      {
                        title: "Quantity",
                        field: "quantity",
                      },
                      {
                        title: "Product Price",
                        field: "productSellingPrice",
                        editable:'never'
                      },

                      {
                        title: "Total Amount",
                        field: "productTotalAmount",
                        editable:'never'
                      },
                    ]}
                    data={rowData.items}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      //   exportButton: true,
                      sorting: true,
                      paging: false,
                      pageSize: 50, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                    editable={{
                      // isEditHidden: (rowData) =>
                      //   Checkpermissions("permissionsSubCatName", "Area Edit"),
                      // isDeleteHidden: (rowData) =>
                      //   Checkpermissions("permissionsSubCatName", "Area Delete"),
                      isEditable:(rowData1)=>rowData.status=='Booking Created'?true:false,
                      isDeletable:(rowData1)=>rowData.status=='Booking Created'?true:false,
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            {
                              props.updateOrder(newData, oldData,rowData,updateTableQuery);
                            }
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                              props.deleteOrderProduct(oldData,rowData,updateTableQuery);
                          }, 600);
                        }),
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                // filtering:true,
                // hideFilterIcons:true,
                paging: true,
                pageSize: 50, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              actions={[
                {
                  icon: () => <PictureAsPdfIcon />,
                  tooltip: "Generate PDF",
                  onClick: (event, rowData) => {
                    props.generateOrderPDF(rowData._id);
                  },
                },
              ]}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteOrder:(id,updateTableQuery)=>dispatch(deleteOrder(id,updateTableQuery)),
    getOrderById:(id)=>dispatch(getOrderById(id)),
    setHeading: (data) => dispatch(setHeading(data)),
    generateOrderPDF: (_id) => dispatch(generateOrderPDF(_id)),
    updateOrder:(data,oldData,rowData,updateTableQuery)=>dispatch(updateOrder(data,oldData,rowData,updateTableQuery)),
    deleteOrderProduct:(data,rowData,updateTableQuery)=>dispatch(deleteOrderProduct(data,rowData,updateTableQuery))
  };
};
const mapStateToProps = (state, ownProps) => ({
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
  shopDataList: state.ShopReducer.shopDataList,
  isFetchingShopData: state.ShopReducer.isFetchingShopData,
  orderBookingDataList: state.OrderBookingReducer.orderBookingDataList,
  isFetchingOrderBookingData:
    state.OrderBookingReducer.isFetchingOrderBookingData,
  generateOrderPDFList: state.OrderBookingReducer.generateOrderPDFList,
  isFetchingGenerateOrderPDF:
    state.OrderBookingReducer.isFetchingGenerateOrderPDF,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrderBooking);
