import { filter } from "lodash";
import API from "../../../../global/api";
export const requestAddUserRes = () => {
    return {
        type: "REQUEST_ADD_USER_RES",
    };
};
export const successAddUserRes = (data) => {
    return {
        type: "SUCCESS_ADD_USER_RES",
        payload: data,
    };
};
export const errorAddUserRes = () => {
    return {
        type: "ERROR_ADD_USER_RES",
    };
};
//add customer
export const addUserRes = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddUserRes());
        API.post(`/users/register`, data).then((res) => {
            handleVisible(res.data.message);
            let getData = res.data;
            dispatch(successAddUserRes(getData));
        }).catch((error) => {
            handleDangerVisible(error.response.data.message);
            dispatch(errorAddUserRes());
        })
    };
};
//get customer
export const getUserRes = () => {
    return (dispatch) => {
        dispatch(requestGetUserRes());
        API.get(`/users`).then((res) => {
            let getData = res.data;
            dispatch(successGetUserRes(getData));
        }).catch((error) => {
            dispatch(errorGetUserRes());
        })
    };
};
export const requestGetUserRes = () => {
    return {
        type: "REQUEST_GET_USER_RES",
    };
};
export const successGetUserRes = (data) => {
    return {
        type: "SUCCESS_GET_USER_RES",
        payload: data,
    };
};
export const errorGetUserRes = () => {
    return {
        type: "ERROR_GET_USER_RES",
    };
}
export const requestDeleteUserRes = () => {
    return {
        type: "REQUEST_DELETE_USER_RES",
    };
};
export const successDeleteUserRes = (data) => {
    return {
        type: "SUCCESS_DELETE_USER_RES",
        payload: data,
    };
};
export const errorDeleteUserRes = () => {
    return {
        type: "ERROR_DELETE_USER_RES",
    };
};
export const deleteUserRes = (customer_id) => {
    return (dispatch) => {
        dispatch(requestDeleteUserRes());
        API.delete(`/users/${customer_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteUserRes());
            // dispatch(getUserRes());
        }).catch((error) => {
            dispatch(errorDeleteUserRes());

        })
    };
};
export const requestUpdateUserRes = () => {
    return {
        type: "REQUEST_UPDATE_USER_RES",
    };
};
export const successUpdateUserRes = (data) => {
    return {
        type: "SUCCESS_UPDATE_USER_RES",
        payload: data,
    };
};
export const errorUpdateUserRes = () => {
    return {
        type: "ERROR_UPDATE_USER_RES",
    };
};
export const UpdateUserRes = (newData, oldData, getVendorList) => {
    const data = filter(getVendorList, { 'label': newData.vendorDetails.vendorName })

    console.log('newData', data, newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestUpdateUserRes());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.put(`/users/${oldData._id}`,
            {
                vendorId: data[0].value,
                firstName: newData.firstName,
                userName:newData.userName,
                role: newData.role,
                hash: newData.hash,
            }
        ).then((res) => {
            let getData = res.data;
            dispatch(successUpdateUserRes([newData]));
        }).catch((error) => {
            dispatch(errorUpdateUserRes());
        })
    };
};