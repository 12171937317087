import API, { baseURL } from "../../../../../global/api";
import jsPDF from "jspdf";
import logo from "../../../../../../src/Images/logo.png";
import moment from "moment";
import { isEmpty, sumBy } from "lodash";
import { handleNoDataVisible } from "../../../../../global/globalFunctions";
export const requestAddReceiving = () => {
  return {
    type: "REQUEST_ADD_RECEIVING",
  };
};
export const successAddReceiving = (data) => {
  return {
    type: "SUCCESS_ADD_RECEIVING",
    payload: data,
  };
};
export const errorAddReceiving = () => {
  return {
    type: "ERROR_ADD_RECEIVING",
  };
};

export const addReceiving = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddReceiving());
    API.post(`/receiving`, data)
      .then((res) => {
        //  dispatch(getReceiving());
        dispatch(successAddReceiving(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddReceiving());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const requestGetReceiving = () => {
  return {
    type: "REQUEST_GET_RECEIVING",
  };
};
export const successGetReceiving = (data) => {
  console.log(data, "uuuuuuuuu");
  return {
    type: "SUCCESS_GET_RECEIVING",
    payload: data,
  };
};
export const errorGetReceiving = () => {
  return {
    type: "ERROR_GET_RECEIVING",
  };
};

export const getReceiving = () => {
  return (dispatch) => {
    dispatch(requestGetReceiving());
    API.get(`${baseURL}/receiving?page=0&limit=1000`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "uuuuuuuuuuu");
        dispatch(successGetReceiving(getData));
      })
      .catch((error) => {
        dispatch(errorGetReceiving());
      });
  };
};

export const requestGetReceivingById = () => {
  return {
    type: "REQUEST_GET_RECEIVING_BY_ID",
  };
};
export const successGetReceivingById = (data) => {
  console.log(data, "uuuuuuuuu");
  return {
    type: "SUCCESS_GET_RECEIVING_BY_ID",
    payload: data,
  };
};
export const errorGetReceivingById = () => {
  return {
    type: "ERROR_GET_RECEIVING_BY_ID",
  };
};

export const getReceivingById = (id) => {
  return (dispatch) => {
    dispatch(requestGetReceivingById());
    API.get(`${baseURL}/receiving/${id}`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "uuuuuuuuuuu");
        dispatch(successGetReceivingById(getData));
      })
      .catch((error) => {
        dispatch(errorGetReceivingById());
      });
  };
};

export const requestPDFById = () => {
  return {
    type: "REQUEST_PDF_BY_ID",
  };
};
export const successPDFById = (data) => {
  console.log(data, "uuuuuuuuu");
  return {
    type: "SUCCESS_PDF_BY_ID",
    payload: data,
  };
};
export const errorPDFById = () => {
  return {
    type: "ERROR_PDF_BY_ID",
  };
};

export const getPDFById = (id) => {
  return (dispatch) => {
    dispatch(requestPDFById());
    API.get(`${baseURL}/receiving/${id}`)
      .then((res) => {
        let getData = res.data;

        console.log(getData, "uuuuuuuuuuu");
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "Receiving Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1 = getData.restaurantImage;
        const ReceivedBy = "Receiving Name: " + getData.data.receivedBy;
        const ReceivedDesc =
          "Receiving Description: " + getData.data.recivingDescription;
        const ReceivedDate = "Receiving Date: " + getData.data.receivingDate;
        const headers = [
          ["Code", "Product Name ", "Quantity", "Rate", "Total"],
        ];
        const data = getData.data.receivingData.map((elt, index) => [
          elt.productId.code,
          elt.ProductName,
          elt.requiredProductQuantity,
          elt.marketPrice == 0 ? elt.productPrice : elt.marketPrice,
          (elt.marketPrice == 0 ? elt.productPrice : elt.marketPrice) *
          elt.requiredProductQuantity,
        ]);
        const Total = sumBy(data, "4");
        let content = {
          startY: 220,
          startX: 10,
          head: headers,
          body: data,
          theme: "grid",
        };

        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 200, 120);
        doc.setFontSize(11);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(ReceivedDate, 40, 140, 0, 20);
        doc.text(ReceivedBy, 400, 140, 0, 20);
        doc.text(ReceivedDesc, 40, 160, 0, 20);
        //  doc.text(companyNtn, 400, 160, 0, 20);
        doc.autoTable(content);
        doc.text(`Total Amount: ${Total}`, 440, doc.lastAutoTable.finalY + 20);
        doc.save(
          `Receiving Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        dispatch(successPDFById(getData));
      })
      .catch((error) => {
        dispatch(errorPDFById());
      });
  };
};
export const requestSearchRecivingByDate = () => {
  return {
    type: "REQUEST_SEARCH_Reciving_BY_DATE",
  };
};
export const successSearchRecivingByDate = (data) => {
  console.log(data, "uuuuuuuuu");
  return {
    type: "SUCCESS_SEARCH_Reciving_BY_DATE",
    payload: data,
  };
};
export const errorSearchRecivingByDate = () => {
  return {
    type: "ERROR_SEARCH_Reciving_BY_DATE",
  };
};

export const getSearchRecivingByDate = (data, startDate, endDate) => {
  return (dispatch) => {
    dispatch(requestSearchRecivingByDate());
    API.post(`${baseURL}/receiving/receiving_with_dates`, data)
      .then((res) => {
        let resData = res.data.data;
        console.log(resData, "uuuuuuuuuuu");
        // let resData = res.data.data;
        let resData1 = res.data;
        if (isEmpty(resData)) {
          handleNoDataVisible();
        } else {
          console.log(resData, resData1, "datessssss");
          const unit = "pt";
          const size = "A4";
          const orientation = "potrait";

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          doc.setFontSize(11);
          const title = "Receiving Report";
          const companyName = `${resData1.resImage.restaurantName}`;
          const companyAddress = `${resData1.resImage.restaurantLocation}`;
          const companyNtn = `${resData1.resImage.ntn}`;
          const logo1 = resData1.restaurantImage;

          const Dates =
            !isEmpty(startDate) || !isEmpty(endDate)
              ? "Issuance Date " + " " + startDate + " to " + endDate
              : "Issuance Date - ";
          const headers = [
            ["Code", "Product Name", "Quantity", "Rate", "Amount"],
          ];
          const data2 = resData.map((elt, index) => [
            elt.productId.code,
            elt.ProductName,
            elt.requiredProductQuantity,
            elt.productId.purchasePrice,
            elt.productId.purchasePrice * elt.requiredProductQuantity,
            //    (elt.inventory.productPrice*elt.productQuantity)
          ]);
          const Total = sumBy(resData, "requiredProductQuantity");
          const TotalAmount = sumBy(data2, "4");
          // const shortTotal=Total.slice(12);
          let content = {
            startY: 190,
            startX: 10,
            head: headers,
            body: data2,
            theme: "grid",
          };
          doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 40, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 60, 0, 20);
          doc.text(companyNtn, 150, 80, 0, 20);
          doc.setFontSize(14);
          doc.setFont(undefined, "bold");
          doc.text(title, 240, 120);
          doc.setFontSize(12);
          doc.setFont(undefined, "normal");
          doc.text(`From ${startDate} to ${endDate}`, 220, 140);

          doc.autoTable(content);
          doc.text(
            `Total Quantity: ${Total.toFixed(2)}`,
            400,
            doc.lastAutoTable.finalY + 20,
            0,
            20
          );
          doc.text(
            `Total Amount: ${TotalAmount.toFixed(2)}`,
            400,
            doc.lastAutoTable.finalY + 40,
            0,
            20
          );
          doc.save(
            ` Receiving Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          );
        }
        dispatch(successSearchRecivingByDate(resData));
      })
      .catch((error) => {
        dispatch(errorSearchRecivingByDate());
      });
  };
};

// Purchase Report with Date //
export const requestGenerateVendorReportPDF = () => {
  return {
    type: "REQUEST_GENERATE_VENDOR_REPORT_PDF",
  };
};
export const successGenerateVendorReportPDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_VENDOR_REPORT_PDF",
    payload: data,
  };
};
export const errorGenerateVendorReportPDF = () => {
  return {
    type: "ERROR_GENERATE_VENDOR_REPORT_PDF",
  };
};

export const generateVendorReportPDF = (data) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateVendorReportPDF());
    API.post(`/receiving/receiving_with_dates`,data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        //   const fromDate = "From Date " + " " + startDate;
        //   const toDate = "To Date " + " " + endDate;
        doc.setFontSize(6);
        const headers = [["Sr#","Date", "Vendor Name", "Received By", "Total","Discount","Freight","Grand Total"]];

        const data = getData.data.map((x, i) => [
          i + 1,
          x.receivingDate,
          x.vendorId.vendorName,
          x.receivedBy,
          x.total,
          x.discount,
          x.freight,
          x.grandTotal
        ]);
        data.push(["","","","Total",sumBy(data,'4'),sumBy(data,'5'),sumBy(data,'6'),sumBy(data,'7')])
        let content = {
          margin: {
            left: 5,
            right: 10,
            // size:2
          },
          startY: 120,
          styles: { fontSize: 11 },
          head: headers,
          body: data,
          theme: "grid",
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 220, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 175, 60, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 200, 80, 0, 20);
        doc.setFontSize(15);
        doc.setFont("bold");
        doc.text("Purchase Report", 220, 100, 0, 20);
        doc.setFontSize(12);
        doc.setFont("TimesNewRoman", "Normal");
        // doc.rect(15, 120, 550, 60);
        // doc.text(`Purchase Date:${getData.data.receivingDate} `, 30, 140, 0, 20);
        // doc.text(`WareHouse Name: ${getData.data.restaurantId.wareHouseName}`, 330, 140, 0, 20);
        // doc.text(`Vendor Name: ${getData.data.vendorId.vendorName} `, 30, 160, 0, 20);
        // doc.text(`Received By: ${getData.data.receivedBy} `, 330, 160, 0, 20);
        doc.setFontSize(12);
        doc.autoTable(content);
        doc.setFontSize(12);
        let finalY = doc.lastAutoTable.finalY;
        let total=sumBy(data, "7")
        doc.setFontSize(15);
        doc.setFont("TimesNewRoman", "bold");
        // doc.text(`Total Amount ${total.toFixed(2)}`, 410, finalY + 15);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Purchase Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateVendorReportPDF(getData));
      })
      .catch((error) => {
        console.log(error,"ttttttttttttt")
        dispatch(errorGenerateVendorReportPDF());
      });
  };
};
// purchase Report Item Wise
export const requestGeneratePurchaseReportItemWise = () => {
  return {
    type: "REQUEST_GENERATE_VENDOR_REPORT_PDF",
  };
};
export const successGeneratePurchaseReportItemWise = (data) => {
  return {
    type: "SUCCESS_GENERATE_VENDOR_REPORT_PDF",
    payload: data,
  };
};
export const errorGeneratePurchaseReportItemWise = () => {
  return {
    type: "ERROR_GENERATE_VENDOR_REPORT_PDF",
  };
};

export const generatePurchaseReportItemWise = (data) => {
  console.log(data,'ooooooooooooo')
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGeneratePurchaseReportItemWise());
    API.post(`/receiving/receiving_product_report`,data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        //   const fromDate = "From Date " + " " + startDate;
        //   const toDate = "To Date " + " " + endDate;
        doc.setFontSize(6);
        const headers = [[ "Vendor Name", "Product Name", "Price","Quantity","Total"]];

        const data = getData.data.map((x, i) => [
          x.vendorName.vendorName,
          x.ProductName,
          x.productSellingPrice,
          x.requiredProductQuantity,
          x.totalPurchaseValue
        ]);
        data.push(["","Total","",sumBy(data,'3'),sumBy(data,'4').toFixed(2)])
        let content = {
          margin: {
            left: 5,
            right: 10,
            // size:2
          },
          startY: 120,
          styles: { fontSize: 11 },
          head: headers,
          body: data,
          theme: "grid",
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 220, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 175, 60, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 200, 80, 0, 20);
        doc.setFontSize(15);
        doc.setFont("bold");
        doc.text("Purchase Report", 220, 100, 0, 20);
        doc.setFontSize(12);
        doc.setFont("TimesNewRoman", "Normal");
        // doc.rect(15, 120, 550, 60);
        // doc.text(`Purchase Date:${getData.data.receivingDate} `, 30, 140, 0, 20);
        // doc.text(`WareHouse Name: ${getData.data.restaurantId.wareHouseName}`, 330, 140, 0, 20);
        // doc.text(`Vendor Name: ${getData.data.vendorId.vendorName} `, 30, 160, 0, 20);
        // doc.text(`Received By: ${getData.data.receivedBy} `, 330, 160, 0, 20);
        doc.setFontSize(12);
        doc.autoTable(content);

        doc.setFontSize(12);
        let finalY = doc.lastAutoTable.finalY;
        // doc.text(`Total Amount ${sumBy(data, "4")}`, 410, finalY + 15);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Purchase Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGeneratePurchaseReportItemWise(getData));
      })
      .catch((error) => {
        console.log(error,'rrrrrrrrrrrrrrrr')
        dispatch(errorGeneratePurchaseReportItemWise());
      });
  };
};

// Generate Purchase PDF

export const requestGeneratePurchasePDF = () => {
  return {
    type: "REQUEST_GENERATE_PURCHASE_PDF",
  };
};
export const successGeneratePurchasePDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_PURCHASE_PDF",
    payload: data,
  };
};
export const errorGeneratePurchasePDF = () => {
  return {
    type: "ERROR_GENERATE_PURCHASE_PDF",
  };
};

export const generatePurchasePDF = (id) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGeneratePurchasePDF());
    API.get(`/receiving/${id}`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "Dataaaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        doc.setFontSize(6);
        // doc.rect(15, 170, 550, 50);
        const headers = [
          [
            "Sr.No",
            "Product Label",
            "Available Qty",
            "Purchased Qty",
            "No Of Carton",
            "Product Price",
            "Amount"
          ],
        ];
        getData.data.receivingData.push({ProductName:"Sub Totals",
          availableProductQuantity:0,
          requiredProductQuantity:sumBy(getData.data.receivingData,"requiredProductQuantity"),
          NoOfCarton:sumBy(getData.data.receivingData,"NoOfCarton"),
          productPrice:0,
        })
        const data = getData.data.receivingData.map((x, i) => [
          getData.data.receivingData.length-1==i?"": i + 1,
          x.ProductName,
          x.availableProductQuantity,
          x.requiredProductQuantity,
          x.NoOfCarton,
          x.productPrice,
          (x.requiredProductQuantity * x.productPrice).toFixed(2)
        ]);

        let content = {
          margin: {
            left: 15,
            right: 30,
            // size:2
          },
          startY: 190,
          styles: { fontSize: 9 },
          // setFontSize(6),
          head: headers,
          body: data,
          theme: "grid",
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 220, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 175, 60, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 200, 80, 0, 20);
        doc.rect(15, 100, 550, 60);
        doc.text(
          `Purchase Date:${getData.data.receivingDate} `,
          30,
          120,
          0,
          20
        );
        doc.text(`WareHouse Name: ${getData.data.restaurantId.wareHouseName}`, 335, 120, 0, 20);
        doc.text(`Vendor Name: ${getData.data.vendorId.vendorName} `, 30, 140, 0, 20);
        doc.text(`Received By: ${getData.data.receivedBy} `, 335, 140, 0, 20);

        doc.setFontSize(6);
        // doc.rect(15, 160, 550, 100);
        doc.autoTable(content);
        doc.setFontSize(14);
        let finalY = doc.lastAutoTable.finalY;
        // doc.rect(100, finalY + 20, 450, 140);
        // ${sumBy(data, "6")}
        doc.text(
          `Sub Total: `,
          440,
          finalY + 20
        );
        doc.text(
          `${getData.data.total}`,
          520,
          finalY + 20
        );
        doc.text(
          `Discount: `,
          440,
          finalY + 40
        );
        doc.text(
          `${getData.data.discount}`,
          520,
          finalY + 40
        );
        doc.text(
          `Freight: `,
          440,
          finalY + 60
        );
        doc.text(
          `${getData.data.freight}`,
          520,
          finalY + 60
        );
        doc.text(
          `Grand Total: `,
          440,
          finalY + 80
        );
        doc.text(
          `${getData.data.grandTotal}`,
          520,
          finalY + 80
        );
        // doc.text(
        //   `Discount: ${getData.data[0].bookingDiscount}`,
        //   420,
        //   finalY + 60
        // );
        // doc.line(400, finalY + 70, 550, finalY + 70);
        // doc.text(
        //   `Net Total: ${
        //     sumBy(getData.data[0].data, "totalSaleValue") -
        //     getData.data[0].bookingDiscount
        //   }`,
        //   420,
        //   finalY + 90
        // );
        // doc.text(`Return:${0}`, 420, finalY + 110);
        // doc.line(400, finalY + 120, 550, finalY + 120);
        // doc.text(`${0}`, 430, finalY + 135);
        doc.setProperties(
          `Purchase Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGeneratePurchasePDF(getData));
      })
      .catch((error) => {
        dispatch(errorGeneratePurchasePDF());
      });
  };
};

export const requestGetPurchaseByDate = () => {
  return {
    type: "REQUEST_GET_PURCHASE_BY_DATE",
  };
};
export const successGetPurchaseByDate = (data) => {
  return {
    type: "SUCCESS_GET_PURCHASE_BY_DATE",
    payload: data,
  };
};
export const errorGetPurchaseByDate = () => {
  return {
    type: "ERROR_GET_PURCHASE_BY_DATE",
  };
};
export const getPurchaseByDate = (data) => {
  return (dispatch) => {
    dispatch(requestGetPurchaseByDate());
    API.post(`/receiving/receiving_with_dates`,data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetPurchaseByDate(data1));
      })
      .catch((error) => {
        dispatch(errorGetPurchaseByDate());
      });
  };
};

export const DeleteRequestReceiving = () => {
  return {
      type: "DELETE_REQUEST_RECEIVING",
  };
};
export const DeleteSuccessReceiving = (user_id) => {
  return {
      type: "DELETE_SUCCESS_RECEIVING",
      user_id: user_id,
  };
};
export const DeleteErrorReceiving = () => {
  return {
      type: "DELETE_ERROR_RECEIVING",
  };
};
export const deleteReceiving = (vendorId,updateTableQuery) => {
  return dispatch => {
      dispatch(DeleteRequestReceiving());
      API.delete(`/receiving/${vendorId}`)
          .then(res => {
             // dispatch(getHallData());
              dispatch(DeleteSuccessReceiving(vendorId))
              updateTableQuery()
          }).catch((error) =>
          {
              dispatch(DeleteErrorReceiving())
  });
  }
};
export const UpdateRequestReceiving = () => {
  return {
      type: "UPDATE_REQUEST_RECEIVING",
  };
};
export const UpdateSuccessReceiving = (data) => {
  return {
      type: "UPDATE_SUCCESS_RECEIVING",
      payload: data,
  };
};

export const UpdateErrorReceiving = () => {
  return {
      type: "UPDATE_ERROR_RECEIVING",
  };
};

export const updateReceiving = (data, oldData,updateTableQuery) => {
  return dispatch => {
      dispatch(UpdateRequestReceiving());
      console.log(data,'hmhm')
      API.put(`/receiving/${oldData._id}`,
          {
            receivedBy: data.receivedBy,
            receivingDate:data.receivingDate,
            recivingDescription:data.recivingDescription,
            total:data.total,
            discount:data.discount,
            freight:data.freight,
            grandTotal:data.grandTotal,     
          },{
      })
          .then(res => {
             // dispatch(getHallData());
              dispatch(UpdateSuccessReceiving([data]))
              updateTableQuery()
          }).catch((error) =>
              dispatch(UpdateErrorReceiving()));
  }
};

export const UpdateRequestReceivingProduct = () => {
  return {
      type: "UPDATE_REQUEST_RECEIVING_PRODUCT",
  };
};
export const UpdateSuccessReceivingProduct = (data) => {
  return {
      type: "UPDATE_SUCCESS_RECEIVING_PRODUCT",
      payload: data,
  };
};

export const UpdateErrorReceivingProduct = () => {
  return {
      type: "UPDATE_ERROR_RECEIVING_PRODUCT",
  };
};

export const updateReceivingProduct = (data, oldData,rowData,updateTableQuery) => {
  return dispatch => {
      dispatch(UpdateRequestReceivingProduct());
      console.log(data,rowData,'hmhm')
      API.put(`/receiving/update_receiving_product/${rowData._id}`,
          {
            _id: data._id,
            requiredProductQuantity:Number(data.requiredProductQuantity),
            productId:data.productId,
            productPrice:Number(data.productPrice),
            difference:Number(data.requiredProductQuantity)-Number(oldData.requiredProductQuantity)
            // receivingDate:data.receivingDate,
            // recivingDescription:data.recivingDescription,
            // total:data.total,
            // discount:data.discount,
            // freight:data.freight,
            // grandTotal:data.grandTotal,     
          },{
      })
          .then(res => {
             // dispatch(getHallData());
              dispatch(UpdateSuccessReceivingProduct([data]))
              updateTableQuery()
          }).catch((error) =>
              dispatch(UpdateErrorReceivingProduct()));
  }
};

export const DeleteRequestReceivingProduct = () => {
  return {
      type: "DELETE_REQUEST_RECEIVING_PRODUCT",
  };
};
export const DeleteSuccessReceivingProduct = (user_id) => {
  return {
      type: "DELETE_SUCCESS_RECEIVING_PRODUCT",
      user_id: user_id,
  };
};
export const DeleteErrorReceivingProduct = () => {
  return {
      type: "DELETE_ERROR_RECEIVING_PRODUCT",
  };
};
export const deleteReceivingProduct = (data,rowData,updateTableQuery) => {
  return dispatch => {
      dispatch(DeleteRequestReceivingProduct());
      API.post(`/receiving/delete_receiving_product/${rowData._id}`,{
        _id:data._id,
        productId:data.productId,
        requiredProductQuantity:data.requiredProductQuantity
      })
          .then(res => {
             // dispatch(getHallData());
              dispatch(DeleteSuccessReceivingProduct(rowData._id))
              updateTableQuery()
          }).catch((error) =>
          {
              dispatch(DeleteErrorReceivingProduct())
  });
  }
};